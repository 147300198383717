import React from "react"
import {Row, Col, Container} from "react-bootstrap"
import SEO from "../components/seo"
import ValuationCTA from "../components/valuationCta";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {Link} from "gatsby"

const HowItWorksPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="How It Works" description="Enter your cars reg number in the box, tell us about your vehicle and receive your cars instant valuation. get in touch today." />

            <Container className="slice">
                <h1 className="text-center font-weight-800 mb-5">How does iCashCars work?</h1>
                <Row>
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <Row className="align-items-center mb-4">
                            <Col xs={2}>
                                <span className="font-weight-bold display-3 mr-2" style={{color: "#d46968"}}>1</span>
                            </Col>
                            <Col xs={10}>
                                <h5 className="mb-0">Enter your cars reg number in the box:</h5>
                            </Col>
                        </Row>
                        <div className="bg-gray p-3 rounded">
                            <p className="mb-0">Type your vehicle registration number into the box on the homepage and click <Link to="/">`Value My Car`</Link>. From this, our database will automatically identify your vehicle. If for some reason your car registration number isn’t recognised, you can enter the details manually instead, including the make, model and derivative.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <Row className="align-items-center mb-4">
                            <Col xs={2}>
                                <span className="font-weight-bold display-3 mr-2" style={{color: "#3e7b9f"}}>2</span>
                            </Col>
                            <Col xs={10}>
                                <h5 className="mb-0">Tell us about your Vehicle</h5>
                            </Col>
                        </Row>
                        <div className="bg-gray p-3 rounded">
                            <p className="mb-0">We’ll request more details from you which are outlined on the online instructions. You’ll need to include information about your car’s history, M.O.T, mileage and service history. Be sure to tell us about any extras too such as privacy glass, upgrade alloys, Sat Navs etc as this could affect the end valuation.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <Row className="align-items-center mb-4">
                            <Col xs={2}>
                                <span className="font-weight-bold display-3 mr-2" style={{color: "#489a41"}}>3</span>
                            </Col>
                            <Col xs={10}>
                                <h5 className="mb-0">Receive your cars instant valuation</h5>
                            </Col>
                        </Row>
                        <div className="bg-gray p-3 rounded">
                            <p className="mb-0">We’ll then be able to give you your instant online valuation and will send it to you by email and text also. If you’re satisfied with the valuation, you can then pick a convenient time and date for an appointment. If everything is then in order, we’ll arrange a bank transfer for the agreed amount*.
                            </p>
                        </div>
                    </Col>
                </Row>
                <p className="mt-4 mb-0">
                    *We will use the information given to us to provide our customers with a valuation. This is a completely free service and you are under no obligation to sell your car to us. The valuation you receive online is subject to an inspection of the car to ensure we give you the most accurate and best quote.
                </p>
            </Container>
            <ValuationCTA></ValuationCTA>
        </Layout>
    )
}

export const data = graphql`
    {
          file(relativePath: { eq: "sara-banner-2.png" }) {
              childImageSharp {
                fluid {
                        ...GatsbyImageSharpFluid
                  }
              }
        }
    }
`

export default HowItWorksPage
